.tasks-container {
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 0 auto;
}

h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #ffcc00;
}

.daily-rewards, .tasks-list {
  margin-bottom: 20px;
}

.task {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #252525;
  border-radius: 8px;
  height: 60px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reward span, .task span {
  font-size: 16px;
  color: #ffffff;
}

.container {
  color: #ff6600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task a {
  padding: 5px 10px;
  color: #fff;
  border: solid 1px #28a745;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.task button {
  padding: 5px 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.task button:hover {
  background-color: #218838;
}

.highlight {
  color: #ff6600;
}

.done {
  background: url("../icons/done.svg");
  height: 50px;
  width: 50px;
  background-size: contain;
  background-repeat: no-repeat;
}

.tobeclaimed {
  color: red;
}