$background-color: #000000;
$primary-color: #ffcc00;
$secondary-color: #ff6600;
$text-color: #ffffff;
$button-bg-color: #252525;
$button-bg-hover-color: #444466;
$button-text-color: #ffffff;
$bg-color: #6d6d6d;
$button-disabled-text-color: #c8c8c8;
$font-family: 'Play', cursive;
$border-radius: 10px;

.referral-dashboard {
  padding: 20px;
  color: $text-color;
  font-family: $font-family;
  border-radius: $border-radius;
  max-width: 600px;
  margin: 0 auto;

  h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }

  .referral-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .referral {
      padding: 10px;
      background-color: $bg-color;
      border-radius: $border-radius;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .referral-name {
        font-size: 16px;
      }

      .referral-coins {
        font-size: 16px;
        color: $primary-color;
      }
    }
  }

  .referral-button {
    padding: 10px 20px;
    background-color: rgb(0, 149, 255);
    color: $button-text-color;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    display: block;
    width: 100%;
    text-align: center;

    &:hover {
      background-color: $button-bg-hover-color;
    }
  }
}
  .copy {
    color: $button-text-color;
    border: none;
    border-radius: $border-radius;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
    display: block;
    text-align: center;

    &:hover {
      background-color: $button-bg-hover-color;
    }
  }

.container{
    display: flex;
    justify-content: center;
    align-items: center;
}
.no-referrals{
  display:grid;
  place-items:center;

}
.dflex{
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.dflex-around{
  display:flex;
  justify-content:space-between;
  align-items:center;
}
.referral-info{
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: flex-start;
  padding: 5px;
  align-items: center;
  color:rgba(255, 255, 255, 0.774);
  border-radius: 10px;
  height:max-content;
  p{
    margin: 0;

  }
}
.main-info{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  align-items: center;
  border:solid 1px $bg-color;
  border:solid 1px $bg-color;

  color:rgba(255, 255, 255, 0.774);
  border-radius: 10px;
  height:max-content
  p{
    margin: 0;

  }
}
.refer-info{
  color:whitesmoke;
}
.dflex-start{
  display:flex;
  justify-content:space-between;
  align-items:flex-start;
  flex-direction: column-reverse;
}