$background-color: #000000;
$primary-color: #ffcc00;
$secondary-color: #ff6600;
$text-color: #ffffff;
$button-bg-color: #252525;
$button-bg-hover-color: #444466;
$button-text-color: #ffffff;
$button-disabled-bg-color: #6d6d6d;
$button-disabled-text-color: #c8c8c8;
$font-family: 'Play', cursive;
$border-radius: 10px;

.tasks-container {
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: $primary-color;
  }
  
  .daily-rewards, .tasks-list {
    margin-bottom: 20px;
  }
  
 .task {
    padding: 10px;
    margin-bottom: 10px;
    background-color: $button-bg-color;
    border-radius: 8px;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .reward span, .task span {
    font-size: 16px;
    color: $text-color;
  }
  .container{
    color: $secondary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .task a {
    padding: 5px 10px;
    color: #fff;
    border: solid 1px #28a745;
    text-decoration: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }


  .task button {
    padding: 5px 10px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .task button:hover {
    background-color: #218838;
  }
  .highlight {
    color: $secondary-color;
  }
  .done{
    background: url('../icons/done.svg');
    height: 50px;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;

  }
  .tobeclaimed{
    color:red;
  }