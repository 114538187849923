$background-color: #000000;
$primary-color: #ffcc00;
$secondary-color: #ff6600;
$text-color: #ffffff;
$button-bg-color: #252525;
$button-bg-hover-color: #444466;
$button-text-color: #ffffff;
$button-disabled-bg-color: #6d6d6d;
$button-disabled-text-color: #c8c8c8;
$font-family: 'Play', cursive;
$border-radius: 10px;
$background-image:linear-gradient(10deg, #2f2d2c, #5b370d, #242424);
*{
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  color: $text-color;
  font-family: $font-family;
  height: 100vh;
  animation: changeBg 10s infinite ;
  animation-timing-function: ease-in-out;
  background-image: linear-gradient(140deg, #000000, #222427, #000000);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.highlight {
  color: $secondary-color;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 20px;
  color: $primary-color;
}

p {
  font-size: 1.2em;
  margin: 10px 0;
}

button {
  margin: 10px;
  padding: 10px 20px;
  background-color: $button-bg-color;
  color: $button-text-color;
  border: none;
  border-radius: $border-radius;
  font-family: $font-family;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:disabled {
    background-color: $button-disabled-bg-color;
    color: $button-disabled-text-color;
    cursor: not-allowed;
  }
}


.tap-out{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 300px;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
}
.tap-mid{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
  width: 250px;
  background-color: transparent;
  border-radius: 50%;
  background-blend-mode: multiply;
  cursor: pointer;
}
.game-out{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top:10px;
}
.tap-out {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.2s ease-in-out;

}


.stats{
  display: flex;
  justify-content: space-around;
  width: 100%;
  .logo{
    background-image: url('../img/logo-bg-rem.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 25px;
    width: 30px;
  }
  .tokens{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.boosters{
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 100%;
}

@keyframes particles {
  0% {
    transform: translate(0, 0);
    opacity: 1;
  }
  100% {
    transform: translate(100px, 300px);
    opacity: 0;
  }
}






@keyframes plusOneAnimation {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}

.plus-one {
  position: absolute;
  color: $primary-color;
  font-size: 24px;
  animation: plusOneAnimation 0.2s ease-out;
  pointer-events: none;
  z-index: 2;
}


@keyframes bubble{
  0%{
      transform: translateY(0vh);
  }
  100%{
      transform: translateY(-100vh);
  }
}
@keyframes squeeze {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes shake {
  0% {
    transform: rotate(0deg);
    
  }
  25% {
    transform: rotate(5deg);

  }
  50% {
    transform: rotate(-5deg);

  }
  75% {
    transform: rotate(5deg);

  }
  100% {
    transform: rotate(0deg);

  }
}

.tap-bar{
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  .tap-fill{
    background-color: $secondary-color;
    height: 10px;
    width: 0;
    transition: width 0.2s ease-in-out;
    border-radius: 10px;

  }
}
.dflex{
  display: flex;
  align-items: center;
  justify-content: center;
}
.dflex-col{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tap-in{
  background-color: rgb(157, 231, 244);
  height: 50px;
  width: 80px;
  z-index: -1;
  border-radius: 50%;
}
.tap-mid{
  background-image: url('../img/mecha-tap.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  
}
.tap-out{
  background:radial-gradient(circle, rgba(75, 71, 71, 0.284) 0%, rgba(145, 105, 17, 0.134) 100%);
  margin: 20px;
}
body:has(.plus-one){
  .tap-out{
    animation: shake 0.2s linear infinite;
  }
}
.collect-coins{
  width: max-content;
  height:70px;
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  margin: 10px;
  color: white !important;;
  font-size: 1.5rem;
  &:active{
    transform: scale(0.9);
  }
  .currency{
    height:40px;
    width: 40px;
  }
}
