.loading-img {
  background-image: url("../img/mecha-main-loading.jpeg");
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

.loading {
  font-size: 1rem;
  color: #ffcc00;
}

.heading-1 {
  padding: 10px;
  color: #ffcc00;
  font-size: 3rem;
  text-shadow: 4px 2px 4px #000000;
  -webkit-text-stroke: 1px #000000;
  font-weight: 900;
}

.tg, .x, .yt {
  color: #ffffff;
  font-family: "Play", cursive;
  animation: changeBg 10s infinite;
  animation-timing-function: ease-in-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 25px;
  width: 25px;
  padding: 20px;
}

.typer {
  font-size: 2.5rem;
  color: white;
  text-shadow: 4px 7px 10px #000000;
  font-weight: 900;
}

.x {
  background-image: url("../icons/x.svg");
}

.yt {
  background-image: url("../icons/yt.svg");
}

.tg {
  background-image: url("../icons/tg.svg");
}

.social-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  gap: 20px;
}