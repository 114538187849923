.background {
  background-color: #000000;
  color: #ffffff;
  font-family: "Play", cursive;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.heading {
  font-size: 24px;
  margin-bottom: 20px;
  color: #ff6600;
}

.message {
  font-size: 16px;
  color: #ffcc00;
  text-align: center;
}

.play-link {
  color: white;
  text-decoration: none;
  font-size: 16px;
  background-color: rgb(179, 46, 68);
  padding: 10px 20px;
  border-radius: 10px;
}
.play-link:hover {
  background-color: rgba(179, 46, 68, 0.8);
}

img {
  border-radius: 3%;
  margin-bottom: 20px;
}