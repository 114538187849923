::-webkit-scrollbar {
  display: none;
}

.metric {
  height: 25px;
  width: 25px;
  background-image: url("./img/logo-bg-rem.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 4px;
}