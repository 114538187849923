$background-color: #000000;
$primary-color: #ffcc00;
$secondary-color: #ff6600;
$text-color: #ffffff;
$button-bg-color: #252525;
$button-bg-hover-color: #444466;
$button-text-color: #ffffff;
$button-disabled-bg-color: #6d6d6d;
$button-disabled-text-color: #c8c8c8;
$font-family: 'Play', cursive;
$border-radius: 10px;
$background-image:linear-gradient(10deg, #2f2d2c, #5b370d, #242424);

.loading-img{
    background-image: url('../img/mecha-main-loading.jpeg');
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    z-index: 10;
}
.loading{
    font-size: 1rem;
    color: $primary-color;
    
}
.heading-1{
    padding:10px;
    color: $primary-color;
    font-size: 3rem;
    text-shadow: 4px 2px 4px $background-color;
    //stroke
    -webkit-text-stroke: 1px $background-color;
    //blend the text
    font-weight: 900;
}

.tg,.x,.yt{
    color: $text-color;
    font-family: $font-family;
    animation: changeBg 10s infinite ;
    animation-timing-function: ease-in-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 25px;
    width: 25px;
    padding: 20px;
}
.typer{
    font-size: 2.5rem;
    color: white;
    text-shadow: 4px 7px 10px $background-color;
    font-weight: 900;

}
.x{
    background-image: url('../icons/x.svg');
}
.yt{
    background-image: url('../icons/yt.svg');
}
.tg{
    background-image: url('../icons/tg.svg');
}
.social-media{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    gap: 20px;
}