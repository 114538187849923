.leader {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-size: 1.2em;
}
.leader.title {
  font-weight: bold;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  width: 300px;
  height: 70vh;
  overflow-y: scroll;
}
.leaderboard .leader:has(.rank):nth-child(1) .rank {
  color: #ffcc00;
}
.leaderboard .leader:has(.rank):nth-child(2) .rank {
  color: #ff6600;
}
.leaderboard .leader:has(.rank):nth-child(3) .rank {
  color: #ff9900;
}

.leaderboard-titles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
}

.leaderboard-body {
  display: flex;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  margin: 10px;
  overflow-y: scroll;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}