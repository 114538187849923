.modal-overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(20, 7, 7, 0.236);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all 3s ease-in-out;
}

.modal-content {
  background: #000000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.close-button {
  align-self: flex-end;
  background: transparent;
  color: #ff6600;
  font-weight: 800;
}

.daily-rewards .reward:last-child {
  border-bottom: none;
}

.daily-rewards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.collected {
  background: rgba(1, 89, 1, 0.644);
}