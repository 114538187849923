@import url('https://unpkg.com/css.gg@2.0.0/icons/css/gift.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/user-add.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/work-alt.css');
@import url('https://unpkg.com/css.gg@2.0.0/icons/css/games.css');
$secondary-color: #ff6600;

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.navbar {
    width: 100%;
    display: flex;
    position: fixed;
    bottom: 0;
    justify-content: space-around;
    background-size: cover;
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    padding: 10px 0;
    color:black;
  }
  .navbar a {
    color: white;
    text-decoration: none;
    padding: 5px;
    font-size: 16px;
  }
  
  .navbar a.active {
    border-bottom: 2px solid $secondary-color ;
  }
.game-nav{
  color:rgb(255, 149, 0);
}
i{
  color:black;
}
i.game-nav{
  color:$secondary-color;
}