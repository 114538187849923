$background-color: #000000;
$primary-color: #ffcc00;
$secondary-color: #ff6600;
$text-color: #ffffff;
$button-bg-color: #252525;
$button-bg-hover-color: #444466;
$button-text-color: #ffffff;
$button-disabled-bg-color: #6d6d6d;
$button-disabled-text-color: #c8c8c8;
$font-family: 'Play', cursive;
$border-radius: 10px;
$background-image:linear-gradient(10deg, #2f2d2c, #5b370d, #242424);

.leader{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 1.2em;
    &.title{
        font-weight: bold;

    }
}
.leaderboard{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    margin: 10px;
    width: 300px;
    height: 70vh;
    overflow-y: scroll;
    .leader:has(.rank){
        &:nth-child(1){
            .rank{
                color: #ffcc00;
            }
        }
        &:nth-child(2){
            .rank{
                color: #ff6600;
            }
        }
        &:nth-child(3){
            .rank{
                color: #ff9900;
            }
        }
    }
}
.leaderboard-titles{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
}
.leaderboard-body{
    display: flex;
    justify-content: center;
    padding: 10px;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin: 10px;
    overflow-y: scroll;
}
.stats{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}
