$background-color: #000000;
$primary-color: #ffcc00;
$secondary-color: #ff6600;
$text-color: #ffffff;
$button-bg-color: #252525;
$button-bg-hover-color: #444466;
$button-text-color: #ffffff;
$button-disabled-bg-color: #6d6d6d;
$button-disabled-text-color: #c8c8c8;
$font-family: 'Play', cursive;
$border-radius: 10px;
$background-image: linear-gradient(10deg, #2f2d2c, #5b370d, #242424);



.booster_1 {
  color: var(--clr-one);
  border: var(--clr-one) 0.125em solid;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em --clr-one;
  box-shadow: inset 0 0 0.5em 0 var(--clr-one), 0 0 0.5em 0 var(--clr-one);
}

.booster_2 {
  color: var(--clr-two);
  border: var(--clr-two) 0.125em solid;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em --clr-two;
  box-shadow: inset 0 0 0.5em 0 var(--clr-two), 0 0 0.5em 0 var(--clr-two);
}

.booster_3 {
  color: var(--clr-three);
  border: var(--clr-three) 0.125em solid;
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em --clr-three;
  box-shadow: inset 0 0 0.5em 0 var(--clr-three), 0 0 0.5em 0 var(--clr-three);
}


.booster-btns::before {
  animation: scalingShadow 0.5s ease-in-out;
}

@keyframes scalingShadow {
  0% {
    top: 120%;
    transform: perspective(1.5em) rotateX(45deg) scale(0.8, 0.3);
  }

  50% {
    top: 165%;
    transform: perspective(1.5em) rotateX(45deg) scale(0.75, 0.3);
  }

  100% {
    top: 120%;
    transform: perspective(1.5em) rotateX(45deg) scale(0.8, 0.3);
  }
}

.booster_1::before {
  background: var(--clr-one);
}

.booster_2::before {
  background: var(--clr-two);
}

.booster_3::before {
  background: var(--clr-three);
}

.booster-btns::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
  transition: opacity 100ms linear;
}

.booster_1::after {
  box-shadow: 0 0 2em 0.5em var(--clr-one);
  background-color: var(--clr-one);
}

.booster_2::after {
  box-shadow: 0 0 2em 0.5em var(--clr-two);
  background-color: var(--clr-two);
}

.booster_3::after {
  box-shadow: 0 0 2em 0.5em var(--clr-three);
  background-color: var(--clr-three);
}

.active {
  animation: animate 0.5s ease-in-out;
}

@keyframes animate {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-40%);
  }

  100% {
    transform: translateY(0%);
  }
}
.tokens{
  .logo{
    height: 35px;
    width: 40px;
    background-image: url('../img/logo-bg-rem.png');    
    background-size: contain;
    background-repeat: no-repeat;

  }
}
.booster-btns {
  font-size: 0.5rem;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  padding: 0.5em 1em;
  border-radius: 0.25em;
  position: relative;

  &:disabled {
    background-color: $button-disabled-bg-color;
    color: $button-disabled-text-color;
    cursor: not-allowed;
    border: none;
    outline: none;
  }
  button{
    border:solid 1px #ffcc00;
    display: flex;
    justify-content: space-around;
  }
}

button:focus {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.booster-slider {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.booster-btn {
  font-family: Arial, sans-serif;
  font-size: 10px;
  padding: 10px 20px;
  margin: 0 10px;
  border: 2px solid transparent;
  background-color: #1a1a1a;
  color: #fff;
  cursor: pointer;
  outline: none;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.booster-button:hover {
  background-color: #333;
}

.booster-button.active {
  border-color: #fff;
}

.stats-booster {
  display: flex;
  justify-content: space-around;
  font-size: 2rem;
  margin-top: 20px;
  .tokens{
    display: flex;
  }
}

.booster-btns {
  .bot,
  .limit,
  .size {
    height: 50px;
    width: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin-right: 10px;
  }
  background-color: $button-bg-color;
  margin:10px;
  display: flex;
  justify-content:space-between;
  align-items: center;
  font-size:1rem;

  .bot {
    background-image: url('../img/mecha-zoomed.jpeg');
  }
  .size{
    background-image: url('../img/mecha-tap.jpeg');
  }
  .limit{
    background-image: url('../img/mecha-charging.jpeg');
  }
  button{
    border:solid 1px #ffcc00;
    display: flex;
    justify-content: space-around;
    font-size: small;
    align-items: center;
    width: fit-content;
  }
}
.stats-booster{
  display: flex;
}
.dflex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 1rem;
  color:$secondary-color
}