$background-color: #000000;
$primary-color: #ffcc00;
$secondary-color: #ff6600;
$text-color: #ffffff;
$button-bg-color: #252525;
$button-bg-hover-color: #444466;
$button-text-color: #ffffff;
$bg-color: #6d6d6d;
$button-disabled-text-color: #c8c8c8;
$font-family: 'Play', cursive;
$border-radius: 10px;

.background{
    background-color: $background-color;
    color: $text-color;
    font-family: $font-family;
    border-radius: $border-radius;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height:100vh
}
.heading{
    font-size: 24px;
    margin-bottom: 20px;
    color: $secondary-color;
}
.message{
    font-size: 16px;
    color: $primary-color;
    text-align: center;
}
.play-link{
    color: white;
    text-decoration: none;
    font-size: 16px;
    background-color: rgb(179, 46, 68);
    padding: 10px 20px;
    border-radius: $border-radius;
    &:hover{
        background-color: rgb(179, 46, 68, 0.8);
    }
}
img{
    border-radius: 3%;
    margin-bottom: 20px;
}